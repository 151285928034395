<template>
    <div>
        <!-- Tarjeta Ventas -->
        <v-card
            v-if="!VentaStreamingGetter.error"
                class="mx-auto mt-6"
                max-width="260"
                id="CuentaCopiar"
            >
            <v-card-text >
                
                <v-img align="center"
                    height="50"
                    width="300"
                    :src="FiltroPlataformaVenta"
                    >
                    <!--:src="require(`@/assets/img_venta/${FiltroPlataformaVenta}.png`)"-->
                </v-img>

                <!--<div class="text--primary d-none d-print-inline">{{FiltroPlataformaVenta}}</div>-->
                
                <p v-if="venta.renovar == 0" class="text-button text--primary">
                    GRACIAS POR TU COMPRA!
                </p>
                <p v-else class="text-button text--primary">
                    Renovación Exitosa!
                </p>
                
                <div class="text--primary mt-n5 mb-2">
                    <!-- Duración: <strong>{{VentaStreamingGetter.venta.duracion}} días</strong><br> -->
                    Usuario: <strong>{{VentaStreamingGetter.venta.streaming_usuario}}</strong><br>
                    Contraseña: <strong>{{VentaStreamingGetter.venta.streaming_pass}}</strong><br>
                    <div v-if="VentaStreamingGetter.venta.streaming_pin">
                        PIN: <strong>{{VentaStreamingGetter.venta.streaming_pin}}</strong><br>
                    </div>
                    <div class="mb-n1" v-show="!!VentaStreamingGetter.venta.streaming_perfil && VentaStreamingGetter.venta.streaming_perfil != ''">
                        Use unicamente el perfil: <strong>{{VentaStreamingGetter.venta.streaming_perfil}}</strong><br>
                    </div>
                    <div v-show="VentaStreamingGetter.venta.tipodecuenta == '1' && VentaStreamingGetter.venta.streaming_uso">
                        Use unicamente el perfil: <strong>{{VentaStreamingGetter.venta.streaming_uso}}</strong><br>
                    </div>
                    Fecha Compra: <strong>{{FechaCompra}}</strong><br>
                    Fecha Vence: <strong>{{FechaVence}}</strong><br>
                    Cliente: <strong>{{VentaStreamingGetter.venta.nombre}}</strong><br>
                    Celular: <strong>{{VentaStreamingGetter.venta.celular}}</strong><br>
                </div>

                <v-divider class="d-print-none"></v-divider>
                <br>
                <!-- <p class="mt-2"> Como conectar mi cuenta?</p> -->
                <!-- <p class="mt-n5">{{VentaStreamingGetter.venta.plataforma_link_soporte}}</p> -->
                <p class="mt-n3">{{VentaStreamingGetter.venta.streaming_mje}}</p>

            </v-card-text>

            <v-card-actions class="mt-n6 d-print-none">

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="green"
                          icon
                          text
                          v-bind="attrs"
                          v-on="on"
                          @click="WS"
                        >
                        <v-icon>mdi-whatsapp</v-icon>
                        </v-btn>
                    </template>
                  <span>Enviar por Whatsapp</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          icon
                          text
                          v-bind="attrs"
                          v-on="on"
                          @click="Imprimir()"
                        >
                        <v-icon>mdi-printer</v-icon>
                        </v-btn>
                    </template>
                  <span>Imprimir</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="warning"
                          icon
                          text
                          v-bind="attrs"
                          v-on="on"
                          @click="Copiar()"
                        >
                        <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                    </template>
                  <span>Copiar Información</span>
                </v-tooltip>

                <v-spacer></v-spacer>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="error"
                          icon
                          text
                          v-bind="attrs"
                          v-on="on"
                          @click="NuevaVentaStreaming()"
                        >
                        <v-icon>mdi-reply</v-icon>
                        </v-btn>
                    </template>
                  <span>Finalizar</span>
                </v-tooltip>
                
            </v-card-actions>
        </v-card>
        <!-- Tarjeta Ventas -->

        <!--Ventas Producto-->
        <v-stepper v-model="e1" v-else>
          <v-stepper-header>
            <v-stepper-step
                :complete="e1 > 1"
                step="1"
            >
                Plataforma Streaming
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
                :complete="e1 > 2"
                step="2"
            >
                Datos Cliente
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3">
                Confirmación Venta
            </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
            <v-stepper-content step="1">
                
                <v-row
                    align="center"
                    justify="center"
                    no-gutters
                    class="pb-5"
                >

                <v-card
                class="mb-2 pb-5 "
                width="500px"
                color="grey lighten-5"
                align="center"
                justify="center"
                >
                <v-container>
                <!---->
                    <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                     <v-select
                    v-model="venta.plataforma"
                    @change="ConsultarCuenta()"
                    :loading="venta.plataforma == '' ? true : false"
                    :items="PlataformasStreamingGetter.items"
                    :menu-props="{ maxHeight: '1000'}"
                    item-text="nombre"
                    item-value="id"
                    label="Plataforma Streaming"
                    persistent-hint
                    :rules="[v => !!v || 'Item Requerido']"
                    required
                    ></v-select>

                    <v-select
                    v-model="venta.renovar"
                    v-if="venta.nuevo == 1"
                    @change="ConsultarCuenta()"
                    :loading="venta.renovar != '' ? true : false"
                    :items="arreglorenovar"
                    item-text="nombre"
                    item-value="id"
                    menu-props="auto"
                    label="Tipo De Cuenta"
                    persistent-hint
                    required
                    ></v-select>
                    
                    <v-select
                    v-model="venta.duracion"
                    v-if="venta.plataforma != '' && venta.renovar == 0"
                    @change="ConsultarCuenta()"
                    :loading="venta.duracion == '' ? true : false"
                    :items="DuracionStreaming.items"
                    :rules="[v => !!v || 'Item Requerido']"
                    label="Duración"
                    required
                    ></v-select>

                    <v-select
                    v-model="venta.tipodecuenta"
                    v-if="venta.duracion != '' && venta.renovar == 0"
                    @change="ConsultarCuenta()"
                    :loading="venta.tipodecuenta == '' ? true : false"
                    :items="TipodeCuentaStreaming.items"
                    :rules="[v => !!v || 'Item Requerido']"
                    label="Tipo de Cuenta"
                    required
                    ></v-select>

                     <v-btn
                        v-if="DatosPlataformaventa"
                        color="primary"
                        @click="PasoFormulario2()"
                        >
                        Continuar
                    </v-btn>

                </v-form>
                <!---->
                </v-container>

                </v-card>
                </v-row>

            </v-stepper-content>

            <v-stepper-content step="2">
                
                <v-row
                    align="center"
                    justify="center"
                    no-gutters
                    class="pb-5"
                >

                <v-card
                class="mb-2 pb-5 "
                width="500px"
                color="grey lighten-5"
                align="center"
                justify="center"
                >
                <!---->
                    <v-container>
                    <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >

                    <v-radio-group
                    v-if="venta.renovar == 1"
                    v-model="venta.radioBusqueda"
                    row
                    >
                        <v-radio
                            value="cel"
                            @click="BorrarDatosCliente"
                        >
                            <template v-slot:label>
                                <div>Buscar x <strong class="success--text">Celular</strong></div>
                            </template>
                        </v-radio>

                        <v-radio
                            value="email"
                            @click="BorrarDatosCliente"
                        >
                            <template v-slot:label>
                                <div>Buscar x <strong class="primary--text">Email</strong></div>
                            </template>
                        </v-radio>

                    </v-radio-group>
                   
                    <v-text-field
                    v-model="venta.celular"
                    v-if="venta.radioBusqueda == 'cel'"
                    @keyup="BuscarCliente()"
                    @change="BuscarCliente()"
                    :loading ="venta.celular == '' ? true : false"
                    :rules="numberRules"
                    label="Celular"
                    type="number"
                    required
                    ></v-text-field>

                    <v-text-field
                    v-model="venta.email"
                    v-if="venta.radioBusqueda == 'email'"
                    @keyup="BuscarClientexEmail()"
                    @change="BuscarClientexEmail()"
                    :loading ="venta.email == '' ? true : false"
                    :rules="emailRules"
                    label="Email"
                    type="email"
                    required
                    ></v-text-field>

                    <v-text-field
                    v-model="venta.nombre"
                    @click="BuscarCliente()"
                    v-if="venta.renovar == 0 && !!venta.celular"
                    :loading ="venta.nombre == '' ? true : false"
                    :rules="nameRules"
                    label="Nombre Titular"
                    required
                    ></v-text-field>

                    <v-text-field
                    disabled
                    v-model="venta.ItemRenovar.correo"
                    v-if="venta.renovar == 1 && !!venta.ItemRenovar.correo"
                    label="Cuenta a Renovar"
                    required
                    ></v-text-field>

                    <v-btn
                    color="success"
                    @click="BuscarCliente()"
                    v-if="venta.radioBusqueda == 'cel' && !DatosUsuarioVenta && venta.renovar == 1"
                    :disabled="this.venta.celular.length < 9"
                    >
                        Buscar x Celular
                    </v-btn>

                    <v-btn
                    color="success"
                    @click="BuscarClientexEmail()"
                    v-if="venta.radioBusqueda == 'email' && !DatosUsuarioVenta && venta.renovar == 1"
                    :disabled="ComprobarEmail"
                    >
                        Buscar x Email
                    </v-btn>

                      <v-btn
                        v-if="DatosUsuarioVenta"
                        color="primary"
                        @click="PasoFormulario3();"
                        >
                        Continuar
                    </v-btn>

                    <v-btn
                        class="ml-2"
                        color="error"
                        @click="e1 = 1"
                        >
                        Cancelar
                    </v-btn>

                </v-form>
                <!---->
                </v-container>

                </v-card>
                </v-row>

            </v-stepper-content>

            <v-stepper-content step="3">
               
               <v-row
                    align="center"
                    justify="center"
                    no-gutters
                    class="pb-5"
                >

                <v-card
                class="mb-2 pb-5 "
                width="500px"
                color="grey lighten-5"
                align="center"
                justify="center"
                >
                <div v-if="venta.pin == ''">
                    <v-alert v-if="DatosFormulario" type="success" dismissible>
                        REALIZAR VENTA
                    </v-alert>

                    <v-alert v-else type="error">
                        ¡Alerta, Existen campos Erroneos!
                    </v-alert>
                </div>

                <div v-if="AlertaDeVenta">
                    <v-alert v-if="venta.pin != '' && VentaStreaming" :type="VentaStreaming != null && VentaStreaming.status == 401 ? 'error':'success'" absolute top>
                        {{VentaStreaming.mje}}
                    </v-alert>
                </div>

                <!---->
                    <v-container>

                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>Plataforma Streaming</v-list-item-title>
                            <v-list-item-title class='text-red'>{{FiltroPlataforma}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>Duración</v-list-item-title>
                            <v-list-item-subtitle>{{venta.duracion}} días</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content>
                            <v-list-item-title>Tipo de cuenta</v-list-item-title>
                            <v-list-item-subtitle>
                                {{venta.tipodecuenta}} {{DatosStreamingGetter.plural}}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                     <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>Nombre Titular</v-list-item-title>
                            <v-list-item-subtitle>{{venta.nombre}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content>
                            <v-list-item-title>Celular</v-list-item-title>
                            <v-list-item-subtitle>{{venta.celular}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                        <!--
                        <v-list-item-content>
                            <v-list-item-title>Mi Costo</v-list-item-title>
                            <v-list-item-subtitle>{{preciocosto}}</v-list-item-subtitle>
                        </v-list-item-content>
                        -->
                        <v-list-item-content>
                            <v-list-item-title>Sugerido de Venta</v-list-item-title>
                            <v-list-item-subtitle>{{precioventa}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-col
                    cols="8"
                    md="8"
                    >
                    <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                    <v-text-field
                    type="password"
                    v-if="validatepin"
                    :loading="venta.pin == ''"
                    v-model="venta.pin"
                    :counter="4"
                    :rules="pinRules"
                    label="Ingrese PIN"
                    required
                    ></v-text-field>

                    <v-btn
                        v-if="DatosFormulario && !validatepin"
                        :disabled="!valid"
                        color="primary"
                        class="mr-4"
                        @click="validatepin = true"
                        >
                        VENDER
                    </v-btn>

                    <v-btn
                        v-if="venta.pin != ''"
                        :disabled="!valid"
                        color="success"
                        class="mr-4"
                        @click="Vender()"
                        >
                        CONFIRMAR
                    </v-btn>

                    <v-btn
                        class="ml-2 mt-1"
                        color="error"
                        @click="volveralinicio()"
                        >
                        Atras
                    </v-btn>

                </v-form>
                    </v-col>
                <!---->
                </v-container>

                </v-card>
                </v-row>

            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>

    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      :value="true"
      absolute
      centered
      :color="VentaStreaming.status == 401 ? 'error' : 'success'"
    >
      {{ VentaStreaming.mje }}
    </v-snackbar>

    <v-dialog
        persistent
        transition="dialog-top-transition"
        max-width="600"
        v-model="dialogocuentasarenovar"
      >
          <v-card>
            <v-toolbar
              color="primary"
              dark
            > Elija Una Cuenta Para Renovar
            <v-spacer></v-spacer>
            <v-btn 
              elevation="5" 
              text 
              small 
              fab 
              style="cursor: pointer" 
              @click="dialogocuentasarenovar = false"
            >
              X
            </v-btn>
            </v-toolbar>

             <v-card-text>
                 <v-simple-table v-if="!!ClienteVenta.nombre" fixed-header>
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                ID
                            </th>
                            <th class="text-left">
                                CUENTA
                            </th>
                            <th class="text-left">
                                PERFIL
                            </th>
                            <th class="text-left">
                                VENCE
                            </th>
                            <th class="text-left">
                                RENOVAR
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="item in ClienteVenta.nombre"
                        :key="item.id"
                        >
                            <td>{{ item.id }}</td>
                            <td>{{ item.correo }}</td>
                            <td>{{ item.perfil }}</td>
                            <td>{{ item.fecha_vence }}</td>
                            <td>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                        color="success"
                                        @click="RenovarCuenta(item)"
                                         v-bind="attrs"
                                         v-on="on"
                                        >mdi-check
                                        </v-icon>
                                    </template>
                                    <span>Click Para Renovar Cuenta</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                <h1 v-else class="mt-10" >{{ClienteVenta.mje}}</h1>
            </v-card-text>

            <v-card-actions class="justify-end">
              <v-btn
                color="error"
                @click="dialogocuentasarenovar = false"
              >Cerrar</v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>

    </div>
</template>

<script>
function encontrarById(items, id){
    for(var i in items){
        if(items[i].id == id){
            return items[i];
        }
    }
    return null;
}

function delay(n){
    return new Promise(function(resolve){
        setTimeout(resolve,n*100);
    });
}

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

    export default {
        created(){
            this.ObtenerPlataformasStreaming();
            this.venta.usuario = this.DatosdeUsuario.id;
            this.venta.pv = this.DatosdeUsuario.pv;
        },

        data () {
        return {
            dialogocuentasarenovar: false,
            AlertaDeVenta: false,
            snackbar: false,
            validatepin: false,
            e1: 1,
            valid: true,
            venta: { usuario: '', pv: '', plataforma: '', nuevo: 0, renovar: 0, duracion: '', tipodecuenta: '', radioBusqueda: 'cel', nombre: '', celular: '', email: '', cuenta_renovar: '', ItemRenovar: {correo: ''}, pin: '', preciocosto: 0, precioventa: 0},
            nameRules: [
                v => !!v || 'Nombre Requerido',
                //v => (v && v.length >= 4) || 'El nombre debe tener mas de 4 caracteres', 
            ],
            numberRules: [
                v => !!v || 'Número Requerido',
                //v => (v && v >= 0) || 'Debes escribir solo números.',
                //v => (v && v.length >= 10) || 'El numero debe tener mas de 9 caracteres',
            ],
            emailRules: [
                v => !!v || 'E-mail es requerido',
                v => /.+@.+\..+/.test(v) || 'E-mail invalido',
            ],
            pinRules:[
                //v => v.length == 4 || 'PIN debe tener 4 digitos',
            ],
            arreglorenovar:[
                {id:0, nombre:'Nuevo'},
                {id:1, nombre:'Renovar'}
            ],
        }
        },

        watch:{
            VentaStreaming:function(){
                this.AlertaDeVenta = true
                setTimeout(() => { this.AlertaDeVenta = false }, 5000);
            }
        },

        methods: {
            ...mapActions(['ObtenerPlataformasStreaming', 'ObtenerDuracionStreaming', 'ObtenerTipodeCuentaStreaming', 
                           'ObtenerClienteCelular', 'ObtenerPreciosStreaming', 'VenderStreaming','ConsultarDatosStreaming']),
            ...mapMutations(['LlenarVenderStreaming']),

            validate () {
                this.$refs.form.validate()
            },

            reset () {
                this.$refs.form.reset()
                this.$refs.form.resetValidation()
            },

            ConsultarCuenta(){
                this.BuscarRenovacionCuenta();
                if(this.PlataformasStreaming.length == 0){
                    this.ConsultarDatosStreaming(this.venta);
                }
                //if(this.DuracionStreaming.length == 0){
                    this.ObtenerDuracionStreaming(this.venta);
                //}
                //if(this.TipodeCuentaStreaming.length == 0){
                    this.ObtenerTipodeCuentaStreaming(this.venta);
                //}
                this.ConsultarPrecios();
                this.BorrarDatosCliente();
            },

            BuscarCliente(){
                this.venta.ItemRenovar.correo = ''

                if(!!this.venta.celular && this.venta.celular != null){
                   if(this.venta.celular != '' && this.venta.celular.length > 9){
                        this.ObtenerClienteCelular(this.venta);
                        
                        if(this.venta.renovar == 1){
                            this.dialogocuentasarenovar = true;
                        }
                        
                        if(Array.isArray(this.ClienteVenta.nombre)){
                            
                        }else{
                            this.venta.nombre = this.ClienteVenta.nombre;
                        }
                    }
                }
            },

            BuscarClientexEmail(){
                var valor = this.venta.email
                if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(valor)){
                    console.log('email valido')
                    this.ObtenerClienteCelular(this.venta);
                    this.dialogocuentasarenovar = true;
                }
            },

            RenovarCuenta(item){
                this.venta.ItemRenovar = Object.assign({}, item)
                this.dialogocuentasarenovar =false
                this.ConsultarPrecios()
            },

            BuscarRenovacionCuenta(){
                var retorno = encontrarById(this.PlataformasStreamingGetter.items, this.venta.plataforma);
                this.venta.nuevo = retorno.renovar
            },

            ConsultarPrecios(){
                this.ObtenerPreciosStreaming(this.venta);
            },

            PasoFormulario2(){
                this.e1 = 2;
                if(this.venta.renovar == 0){
                    this.venta.preciocosto = this.PreciosStreaming.preciocosto;
                    this.venta.precioventa = this.PreciosStreaming.precioventa;
                }
            },

            PasoFormulario3(){
                this.e1 = 3;
                if(this.venta.renovar == 1){
                    this.venta.preciocosto = this.PreciosStreaming.preciocosto;
                    this.venta.precioventa = this.PreciosStreaming.precioventa;
                    this.venta.tipodecuenta = this.PreciosStreaming.tipodecuenta;
                    this.venta.duracion = this.PreciosStreaming.diasrenovar
                    this.venta.nombre = this.PreciosStreaming.nombrecliente
                    this.venta.celular = this.venta.ItemRenovar.cliente
                 }
            },

            Vender(){
                if(this.venta.renovar == 1){
                    this.venta.duracion = 40
                }
                this.VenderStreaming(this.venta);
                this.snackbar = true;
            },

            NuevaVentaStreaming(){
                var data = {mje: '', error: true}
                this.LlenarVenderStreaming(data)
                this.venta= { usuario: '', pv: '', plataforma: '', nuevo: 0, renovar: 0, duracion: '', tipodecuenta: '', radioBusqueda: 'cel', nombre: '', celular: '', email: '', cuenta_renovar: '', ItemRenovar: {correo: ''}, pin: '', preciocosto: 0, precioventa: 0};
                this.venta.usuario = this.DatosdeUsuario.id;
                this.venta.pv = this.DatosdeUsuario.pv;
                this.e1 = 1
            },

            BorrarDatosCliente(){
                this.venta.ItemRenovar = {correo: ''};
                this.venta.celular = '';
                this.venta.email = '';
                this.venta.nombre = '';
            },

            /* Copiar */
			Copiar: function(){
				var elemento = 'CuentaCopiar';
				var codigoACopiar = document.getElementById(elemento);
				var seleccion = document.createRange();
				seleccion.selectNodeContents(codigoACopiar);
				window.getSelection().removeAllRanges();
				window.getSelection().addRange(seleccion);
				var res = document.execCommand('copy');
				window.getSelection().removeRange(seleccion);
			},
			/* Fin Copiar */

            WS: function(){
                var saltolinea = '%0A'
                 var mensajews = this.FiltroPlataforma+saltolinea+saltolinea//revisar
                 mensajews += "!Gracias por tu compra!"+saltolinea
                 mensajews += "Duración: "+this.VentaStreamingGetter.venta.duracion+" días"+saltolinea
                 var usuarioFormat = this.VentaStreamingGetter.venta.streaming_usuario;
                 usuarioFormat = usuarioFormat.replace('+','%2B');
                 mensajews += "Usuario: "+usuarioFormat+saltolinea
                 mensajews += "Contraseña: "+this.VentaStreamingGetter.venta.streaming_pass+saltolinea
                 if(this.VentaStreamingGetter.venta.streaming_pin){
                     mensajews += "PIN: "+this.VentaStreamingGetter.venta.streaming_pin+saltolinea
                 }
                 if(this.VentaStreamingGetter.venta.streaming_perfil != ''){
                     mensajews += "Use unicamente el perfil: "+this.VentaStreamingGetter.venta.streaming_perfil+saltolinea
                 }
                 if(this.VentaStreamingGetter.venta.tipodecuenta == '1' && this.VentaStreamingGetter.venta.streaming_uso){
                     mensajews += "Use unicamente el perfil: "+this.VentaStreamingGetter.venta.streaming_uso+saltolinea
                 }
                 mensajews += "Fecha Compra: "+this.FechaCompra+saltolinea
                 mensajews += "Fecha Vence: "+this.FechaVence+saltolinea
                 mensajews += "Cliente: "+this.VentaStreamingGetter.venta.nombre+saltolinea
                 mensajews += "Celular: "+this.VentaStreamingGetter.venta.celular+saltolinea+saltolinea
                 mensajews += "Quieres mantener tu historial?"+saltolinea
                 mensajews += "Renueva tu cuenta, antes de:"+saltolinea
                 mensajews += this.FechaVence+saltolinea+saltolinea
                 mensajews += "Como conectar mi cuenta?"+saltolinea
                 mensajews += this.VentaStreamingGetter.venta.plataforma_link_soporte+saltolinea
                 mensajews += "Necesita ayuda?"+saltolinea
                 mensajews += this.VentaStreamingGetter.venta.streaming_mje+saltolinea
                 mensajews += "Terminos y condiciones"+saltolinea
                 mensajews += "is.gd/TERMINOS"
                
                 console.log(mensajews)
                 var url = 'https://api.whatsapp.com/send?phone=57'+this.VentaStreamingGetter.venta.celular+'&text='+mensajews;
                 window.open(url);
            },

			/* Funcion Para Imprimir */
			Imprimir: function() {
				var ficha = document.getElementById('CuentaCopiar');
				var ventimp = window.open(' ', 'popimpr');
				ventimp.document.write( ficha.innerHTML );
				ventimp.document.close();
				ventimp.print( );
				ventimp.close();
			  },
			/* Fin Funcion Para Imprimir */

            volveralinicio: function(){
                this.e1 = 1
                this.venta.pin = ''
            },
        },

        computed:{
            ...mapState(['PlataformasStreaming', 'DuracionStreaming', 'TipodeCuentaStreaming', 'ClienteVenta', 'PreciosStreaming', 'VentaStreaming']),
            ...mapGetters(['PlataformasStreamingGetter', 'DatosdeUsuario', 'DatosStreamingGetter','NotificacionesMenu', 'VentaStreamingGetter']),

            DatosFormulario: function(){
                var respuesta = false;
                if(this.venta.renovar == 0){
                    if(this.venta.duracion != '' &&
                    this.venta.plataforma != '' && 
                    this.venta.tipodecuenta != '' &&  
                    this.venta.celular.length >= 9 &&
                    this.venta.nombre.length >= 4
                    ){
                        respuesta = true;
                    }
                }else{
                    if(this.venta.ItemRenovar.correo != ''){
                        respuesta = true;
                    }
                }
                return respuesta
            },

            DatosPlataformaventa: function(){
                var respuesta = false;
                if(this.venta.renovar == 0){
                    if(this.venta.duracion != '' &&
                    this.venta.plataforma != '' &&
                    this.venta.tipodecuenta != ''){
                        respuesta = true;
                    }
                }else{
                    if(this.venta.plataforma != ''){
                        respuesta = true;
                    }
                }
                return respuesta
            },

            DatosUsuarioVenta: function(){
                var respuesta = false;
                if(this.venta.renovar == 0){
                    if(!!this.venta.celular && !!this.venta.nombre){
                        if(this.venta.celular != null && !!this.venta.nombre != null){
                            if(
                                (this.venta.celular.length > 9 || this.venta.email != '') &&
                                (this.venta.nombre.length >= 4 || this.venta.ItemRenovar.correo != '')
                            ){
                                respuesta = true;
                            }
                        }
                    }
                }else{
                    if((this.venta.ItemRenovar.correo != '' && this.venta.celular != '') || 
                       (this.venta.ItemRenovar.correo != '' && this.venta.email != '')
                      ){
                        respuesta = true;
                    }
                }
                
                return respuesta
            },

            preciocosto: function(){
                if(!!this.venta.preciocosto && this.venta.preciocosto != 0){
                    return '$'+this.venta.preciocosto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }
            },

            precioventa: function(){
                if(!!this.venta.preciocosto && this.venta.preciocosto != 0){
                    return '$'+this.venta.precioventa.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }
            },

            FiltroPlataforma: function(){
                var Nom = encontrarById(this.PlataformasStreamingGetter.items, this.venta.plataforma);
                return Nom != null && this.venta.plataforma ? Nom.nombre : '';
            },

            FiltroPlataformaVenta: function(){
                if(!this.VentaStreaming.error){
                    var Nom = encontrarById(this.PlataformasStreamingGetter.items, this.VentaStreaming.venta.plataforma);
                    return Nom != null ? Nom.img_venta : '';
                }
            },

            FechaCompra: function(){
                if(this.VentaStreaming.venta.duracion){
                    var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
                    var fecha = new Date();
                    fecha.setDate(fecha.getDate());
                    var anno=fecha.getFullYear();
                    var mes= fecha.getMonth();
                    var dia= fecha.getDate();
                    var result = dia+'/'+meses[mes]+'/'+anno
                    return result
                }
            },

            FechaVence:function(){
                if(this.VentaStreaming.venta.duracion){
                    var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
                    var fecha = new Date();
                    fecha.setDate(fecha.getDate() + parseInt(this.VentaStreaming.venta.duracion));
                    var anno=fecha.getFullYear();
                    var mes= fecha.getMonth();
                    var dia= fecha.getDate();
                    var result = dia+'/'+meses[mes]+'/'+anno
                    return result
                }
            },

            ComprobarEmail:function(){
                    var bandera = true
                    var valor = this.venta.email
                    if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(valor)){
                        bandera = false
                    }

                    return bandera
            }

      },

}
</script>